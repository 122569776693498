import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center'
  }
})

function HotelList(props) {
  let { list, classes, mailTo } = props

  return (
    <List dense className={classes.root}>
      {list.map(key => (
        <ListItem key={key}>
          <ListItemText primary={` ${key}`} />
          {mailTo ? (
            <span style={{ textAlign: 'start', width: '33%', fontSize: 12 }}>
              <a href={`mailto:${mailTo}`}>{mailTo}</a>
            </span>
          ) : (
            ''
          )}
        </ListItem>
      ))}
    </List>
  )
}

export default withStyles(styles)(HotelList)
