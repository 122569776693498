import { countriesList as list } from './staticCountries'
export const smartConnect =
  'https://ssl.smart-net.co.il/loginpages/userlogin.shtml'
export const danSmartConnect = 'https://vsz-cluster.danhotels.com:9998/SubscriberPortal/hotspotlogin'
export const BezoneConnect =
  'http://wireless.hp.internal:8080/goform/HtmlLoginRequest'
export const emailPattern = /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/
export const cellphoneLengthPattern = /^\d{10,20}$/
export const cellphonePattern = /^\d*$/
export const lettersRegExpHeb = /[אבגדהוזחטיכךלמםנןסעפףצץקרשת]/
export const englishOnly = /^(?![\s.]+$)[a-zA-Z\s.]*$/
export const recaptchaSitekey = '6LcI5TwUAAAAALrkzdgZC7UzEI8JNp0uPnciNeKe'
export { list }
