import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import HotelList from './HotelList'
const styles = {
  root: {
    margin: '10px'
  },
  title: {
    fontSize: 16
  },
  pos: {
    marginBottom: 14
  },
  Divider: {
    textDecoration: 'underline',
    paddingLeft: '16px',
    paddingRight: '16px',
    textAlign: 'start',
    fontWeight: 700
  }
}

function TermsCard(props) {
  const {
    classes,
    title,
    content,
    list,
    list2,
    title2,
    list3,
    list4,
    list5,
    list6,
    list7,
    list8,
    list9,
    list10,
    list11,
    list12,
    list13,
    list14,
    Divider,
    Divider2,
    Divider3,
    Divider4,
    Divider5,
    Divider6,
    Divider7,
    Divider8,
    Divider9,
    Divider10,
    Divider11,
    Divider12,
    Divider13,
    DPOMail
  } = props
  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography className={classes.pos} variant="h5" component="h2">
          {title}
        </Typography>
        <Typography component="p">{content ? content : ''}</Typography>
        {list ? <HotelList list={list} /> : ''}
        {title2 ? <Typography component="h4"> {title2} </Typography> : ''}
        {Divider ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider}
          </Typography>
        ) : (
          ''
        )}
        {list2 ? <HotelList list={list2} /> : ''}
        {Divider2 ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider2}
          </Typography>
        ) : (
          ''
        )}
        {list3 ? <HotelList list={list3} /> : ''}
        {Divider3 ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider3}
          </Typography>
        ) : (
          ''
        )}
        {list4 ? <HotelList list={list4} /> : ''}
        {Divider4 ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider4}
          </Typography>
        ) : (
          ''
        )}
        {list5 ? <HotelList list={list5} /> : ''}
        {Divider5 ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider5}
          </Typography>
        ) : (
          ''
        )}
        {list6 ? <HotelList list={list6} /> : ''}
        {Divider6 ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider6}
          </Typography>
        ) : (
          ''
        )}
        {list7 ? <HotelList list={list7} /> : ''}
        {Divider7 ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider7}
          </Typography>
        ) : (
          ''
        )}
        {list8 ? <HotelList list={list8} /> : ''}
        {Divider8 ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider8}
          </Typography>
        ) : (
          ''
        )}
        {list9 ? <HotelList list={list9} /> : ''}
        {Divider9 ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider9}
          </Typography>
        ) : (
          ''
        )}
        {list10 ? <HotelList list={list10} /> : ''}
        {Divider10 ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider10}
          </Typography>
        ) : (
          ''
        )}
        {list11 ? <HotelList list={list11} /> : ''}
        {Divider11 ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider11}
          </Typography>
        ) : (
          ''
        )}
        {list12 ? <HotelList list={list12} /> : ''}
        {Divider12 ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider12}
          </Typography>
        ) : (
          ''
        )}
        {list13 ? <HotelList list={list13} /> : ''}
        {Divider13 ? (
          <Typography component="h4" className={classes.Divider}>
            {Divider13}
          </Typography>
        ) : (
          ''
        )}
        {list14 ? <HotelList list={list14} mailTo={DPOMail} /> : ''}
      </CardContent>
    </Card>
  )
}

TermsCard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(TermsCard)
