import React from 'react'
import { withStyles } from '@material-ui/core/styles'
const styles = theme => ({
  inputStyle: {
    width: '70%',
    border: 0,
    padding: '12px 0',
    height: '20px',
    fontSize: '16px',
    fontWeight: 500,
    borderBottom: '3px solid #c8ccd4',
    background: 'none',
    color: 'white',
    transition: 'all 0.15s ease'
  },
  labelContainer: {
    width: ' 100%',
    minWidth: '260px',
    position: 'relative',
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'center'
  },
  label: {
    position: 'absolute',
    fontSize: '16px',
    color: ' gold',
    fontWeight: '500',
    transformOrigin: '0 0',
    transition: 'all 0.2s ease',
    top: '16px',
    left: '16%'
  },
  border: {
    position: ' absolute',
    bottom: 0,
    left: 0,
    height: '2px',
    maxWidth: '280px',
    background: '#07f',
    transform: 'scaleX(0)',
    transformOrigin: '0 0',
    transition: 'all 0.15s ease'
  },
  inputStyleFocus: {
    background: 'none',
    outline: 'none',
    borderBottom: '3px solid #07f',
    width: '70%',
    border: 0,
    padding: '12px 0',
    height: '20px',
    fontSize: '16px',
    fontWeight: 500,
    color: 'white',
    transition: 'all 0.15s ease'
  },
  labelFocus: {
    color: 'rgba(48, 173, 255, 0.90)',
    transform: 'translateY(-30px) translateX(0) scale(0.85)',
    position: 'absolute',
    fontSize: '16px',
    fontWeight: '500',
    transformOrigin: '0 0',
    transition: 'all 0.2s ease',
    top: '16px',
    left: '16%'
  },
  borderFocus: {
    transform: 'scaleX(2)',
    position: ' absolute',
    bottom: 0,
    left: 0,
    height: '2px',
    maxWidth: '280px',
    background: '#07f',
    transformOrigin: '0 0',
    transition: 'all 0.15s ease'
  },
  labelFilled: {
    transform: 'translateY(-30px) translateX(0) scale(0.85)',
    position: 'absolute',
    fontSize: '16px',
    color: ' #9098a9',
    fontWeight: '500',
    transformOrigin: '0 0',
    transition: 'all 0.2s ease',
    top: '16px',
    left: '16%'
  }
})
class HotelInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: ''
    }
  }
  onValChange = e => {
    this.props.sendValue(e.target.value.trim(), e.target.id)
  }
  focusStyles = e => {
    let value = e.target.value
    this.setState(prevState => ({
      checker: !prevState.checker,
      value
    }))
  }
  render() {
    let { classes, error } = this.props
    let { value, checker } = this.state
    let inputStyle = checker ? classes.inputStyleFocus : classes.inputStyle
    let label = checker
      ? classes.labelFocus
      : !checker && value
      ? classes.labelFilled
      : classes.label
    label = !value && error ? label + " error" : label;
    if(!value && error) {
      label = label + " error";
      inputStyle = inputStyle + " error"
    }
    let border = checker
      ? (classes.border, classes.borderFocus)
      : classes.border
    return (
      <label className={classes.labelContainer} htmlFor={this.props.id}>
        <input
          onChange={this.onValChange}
          id={this.props.id}
          onBlur={this.focusStyles}
          onFocus={this.focusStyles}
          type={this.props.type}
          name={this.props.name}
          placeholder={''}
          className={inputStyle}
          autoComplete="off"
          onClick={this.handleClick}
          value={this.props.value}
          required
        />
        <span className={label}>{this.props.placeholder}</span>
        <span className={border} />
      </label>
    )
  }
}
export default withStyles(styles)(HotelInput)

// <HotelInput
//           sendValue={this.logger}
//           id="emailAddress"
//           type="email"
//           name="emailAddress"
//           placeholder={'emaill'}
//         />
