// rgba(47, 58, 72,0.7) color of background
import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Src from '../../../../images/LinkLogo.png'
// What we need -
// - Create Button (full color)
// - Make new Header with Logo
// - Make new Background
// - devide it with edan button (hoolow)
// - Connect to terms and condition
// - Create a Edan Option

// const LinkContainer = () => <div>Hello</div>
const styles = theme => ({
  root: {
    flexGrow: 1,
    background:
      window.location.href.indexOf('newPassword') > -1
        ? 'rgba(47, 58, 72,1)'
        : 'rgba(47, 58, 72,0.7)'
  },
  logo: {
    maxWidth: '150px'
  }
})

class LinkHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    //screenWidth, screenHeight
    let { classes } = this.props

    return (
      <div className="LinkHeader">
        <Grid
          container
          className={classes.root}
          justify="center"
          align="center"
        >
          <Grid item xs={12} ms={12} md={12} lg={12}>
            <img src={Src} alt="logo" className={classes.logo} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

LinkHeader.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(LinkHeader)
