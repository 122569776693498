import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import ConnectionContainer from '../ConnectionContainer'
import TermsAndConditions from '../TermsAndConditions'
const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '1.5vw 0',
    justifyContent: 'center'
  }
})

class MainContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }
  componentWillMount() {
    this.updateDimensions()
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
  }
  updateDimensions = () => {
    this.setState(prevState => ({
      ...prevState,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }))
  }
  getCheckerStatus = () => {
    this.setState(prevState => ({
      ...prevState,
      checked: !prevState.checked
    }))
  }
  checkerStatus = () => {
    return this.state.checked
  }
  render() {
    let { windowHeight, windowWidth } = this.state
    let { classes } = this.props
    let screen = { screenWidth: windowWidth, screenHeight: windowHeight }
    let obj = {
      container: {
        backgroundColor: '#a9a9a9d9',
        borderRadius: screen.screenWidth <= 957 && '15px',
        marginTop: '20px'
      },
      text: {
        fontSize: '20px'
      }
    }
    return (
      <div className="MainContainer">
        <Grid container className={classes.root} spacing={0}>
          <ConnectionContainer
            screen={screen}
            checkerStatus={this.state.checked}
          />
        </Grid>
        <TermsAndConditions
          styleing={obj}
          status={this.state.checked}
          handleChange={this.getCheckerStatus}
          href={'/wifiterms'}
          text={'I accept the '}
          linkText={'wifi Terms and conditions '}
          isStyleNeeded={true}
        />
      </div>
    )
  }
}

MainContainer.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(MainContainer)
