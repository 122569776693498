import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CheckBox from './components/CheckBox'
import Link from '@material-ui/core/Link'
import { Link as Linked } from 'react-router-dom'

const screen = { screenWidth: window.innerWidth, screenHeight: window.innerHeight }

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  link: {
    color: 'rgb(240, 112, 96)'
  }
})

const TermsAndConditions = ({
  classes,
  handleChange,
  status,
  text,
  href,
  linkText,
  linkText2,
  href2,
  text2,
  router = false,
  styleing = { container: {}, text: {} },
  isStyleNeeded
}) => {
  let styleCond = isStyleNeeded
    ? {
        display: 'flex',
        alignItems: screen.screenWidth > 957 && 'center',
        width: '100%',
        justifyContent: 'center',
        padding: '10px 0'
      }
    : { display: 'flex', alignItems: 'center', width: '70%' }


  return (
    <div
      className="TermsAndCondition"
      style={{
        width: '100%',
        marginTop: isStyleNeeded ? '' : '10px ',
        marginBottom: isStyleNeeded ? '10px' : ''
      }}
    >
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}
        spacing={0}
        style={styleing.container}
      >
        <Grid
          item
          xs={12}
          ms={12}
          md={12}
          lg={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div style={styleCond}>
            <CheckBox status={status} handleChange={handleChange} withOutTop={screen.screenWidth <= 957 ? true : false} />
            <Typography style={{ ...styleing.text, color: 'white', lineHeight: screen.screenWidth <= 957 && 1.2 }}>
              {text}
              {router ? (
                href && linkText ? (
                  <span>
                    <Linked target="_blank" to={href} className={classes.link}>
                      {linkText}
                    </Linked>
                    {text2}
                    {href2 && linkText2 ? (
                      <Linked
                        target="_blank"
                        to={href2}
                        className={classes.link}
                      >
                        {linkText2}
                      </Linked>
                    ) : (
                      ''
                    )}
                  </span>
                ) : (
                  ''
                )
              ) : (
                <Link target="_blank" href={href} className={classes.link}>
                  {linkText}
                </Link>
              )}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
TermsAndConditions.propTypes = {
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  href: PropTypes.string
}
export default withStyles(styles)(TermsAndConditions)
