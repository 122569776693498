import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Link } from 'react-router-dom'
const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`
  },

  submit: {
    marginTop: theme.spacing.unit,
    textDecoration: 'none'
  }
})

function NoMatch(props) {
  const { classes } = props
  let url =
    window.location.href.indexOf('link') > -1
      ? `/link`
      : window.location.href.indexOf('bwifi') > -1
      ? `/bwifi`
      : '/'

  return (
    <main className={classes.main}>
      <CssBaseline />
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h1">
          4 0 4
        </Typography>
        <Typography component="h1" variant="h5">
          Oops! Looks like you got lost
        </Typography>
        <Link to={url} className={classes.submit}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Take me home
          </Button>
        </Link>
      </Paper>
    </main>
  )
}

NoMatch.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(NoMatch)
