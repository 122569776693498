import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TermsCard from '../../Components/TermsCard'
import {
  termsWifi,
  wifiTermsList,
  wifiTermsList2,
  wifiTermsListTitle
} from './components/content'
const styles = theme => ({
  root: {
    flexGrow: 1
  }
})

class TermsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    let { classes } = this.props

    return (
      <div className="TermsContainer" style={{ background: '#f8f8f8' }}>
        <Grid
          container
          className={classes.root}
          spacing={0}
          justify="center"
          align="center"
        >
          <Grid item xs={10} ms={10} md={6} lg={6}>
            <TermsCard
              title="Wifi Terms & Conditions"
              list={wifiTermsList}
              content={termsWifi}
              list2={wifiTermsList2}
              title2={wifiTermsListTitle}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

TermsContainer.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(TermsContainer)
