import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
const styles = theme => ({
  title: {
    fontSize: '25px',
    fontWeight: '700'
  },
  subTitle: {
    fontSize: '20px'
  },
  titleDektop: {
    fontSize: '35px',
    fontWeight: '700'
  },
  subTitleDesktop: {
    fontSize: '25px'
  },
  root: {
    borderLeft: '1px solid black',
    padding: '10px'
  }
})

function HeaderBar(props) {
  const { classes, title, subTitle, screen } = props
  const { screenWidth } = screen
  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography
          variant="h1"
          className={screenWidth < 600 ? classes.title : classes.titleDektop}
        >
          {title
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography
          variant="h4"
          className={
            screenWidth < 600 ? classes.subTitle : classes.subTitleDesktop
          }
        >
          {subTitle}
        </Typography>
      </Grid>
    </Grid>
  )
}

HeaderBar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  screen: PropTypes.object.isRequired
}

export default withStyles(styles)(HeaderBar)
