import React, { useState } from "react";
import Logo from '../../images/villaLogo.svg';
import { danSmartConnect } from '../../Keys';
import Checkbox from '@material-ui/core/Checkbox';
import HotelLoader from '../../Components/HotelLoader'
import './style.css'

const params = new URL(window.location.href).searchParams

export default function VillaContainer() {
  const [isChecked, setIsChecked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  function handleChange(event) {
    setIsChecked(event.target.checked);
  };

  function handleSubmit(e) {
    e.preventDefault()
    if (isChecked) {
      setIsLoading(true)
      e.target.submit({
        username: 'danwifiguest',
        password: 'danhotels'
      })
    } else {
      setIsError(true)
    }
  }
  return (
    <div className="villa-container">
      <img className="app-logo" src={Logo} alt="vila accadia" />
      <div className="app-slogan">ALL WE NEED IS WIFI</div>
        <form
          className="app-bottom"
          onSubmit={handleSubmit}
          method="post"
          action={danSmartConnect}
        >
        <input type="hidden" name="username" value="danwifiguest" />
        <input type="hidden" name="password" value="danhotels" />
        <input type="hidden" name="url" value={params.get('url')} />
        <input type="hidden" name="proxy" value={params.get('proxy')} />
        <input type="hidden" name="uip" value={params.get('uip')} />
        <input type="hidden" name="client_mac" value={params.get('client_mac')} />
        <div className="villa-checkbox-wrapper">
          <Checkbox
            checked={isChecked}
            onChange={handleChange}
            classes={{root: 'checkbox', checked: 'checked'}}
          />

          <span>I accept the <a href="/wifiterms" target="_blank">wifi terms and conditions</a></span>
        </div>
        <button className="membership-btn" type="submit">
          {isLoading ? <HotelLoader margin='0px' size={20} color="white" /> : 'FREE WIFI'}
        </button>
        {isError && !isChecked && <div className="villa-error">Please accept terms and conditions</div>}
        </form>

    </div>
  );
}
