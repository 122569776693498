import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Guest from './components/Guest'
import Free from './components/Free'
import { withStyles } from '@material-ui/core/styles'
const styles = theme => ({
  root: {
    flexGrow: 1
  }
})
const ConnetctionContainer = ({ screen, checkerStatus }) => {
  if (
    window.location.href.indexOf('link') > -1
  ) {
    return <Free screen={screen} checkerStatus={checkerStatus} />
  } else if (window.location.href.indexOf('bwifi') > -1) {
    return <Free screen={screen} checkerStatus={checkerStatus} />
  } else {
    return <Free screen={screen} checkerStatus={checkerStatus} />
  }
}
ConnetctionContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  screen: PropTypes.object.isRequired
  // getCheckerStatus: PropTypes.func.isRequired
}
export default withStyles(styles)(ConnetctionContainer)
