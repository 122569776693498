export const termsAndConditionTopList = [
  `The following statement sets out details of the policy adopted by Dan Hotels including: Call Dan Center, Dan Tel Aviv, Dan Panorama Tel Aviv, Dan Accadia Herzliya, Dan Caesarea, Dan Carmel Haifa, Dan Panorama Haifa, King David Jerusalem, Dan Jerusalem, Dan Panorama Jerusalem, Dan Boutique Jerusalem, Dan Eilat, Dan Panorama Eilat, Link Hotel&Hub. (“Dan Hotels” or “We”) to gather and disseminate information, (the “Privacy Policy”).`,
  `The Privacy Policy outlines the types of personal and other data (as defined below) that we gather about users of the services (as defined below), how we collect and make use of such data, including saving, processing and sharing the data with third parties, etc.`,
  `Whenever in this Privacy Policy that we refer to “you” or “yours”’ the intention is to all users who make use of our services (as defined below) and the personal data and other data (as defined below) refers to him or her.`,
  `We collect personal data and other data (as defined below)  through: (1)  websites operated by us from which you are accessing this Privacy Policy, including www.danhotels.com , www.danhotels.co.il , booking.danhotels.com, link-tlv.com  and other websites owned or controlled by Dan Hotels (collectively, the “Websites”); (2)  through the software applications made available by us for use on or through computers and mobile devices (the “Apps”); (3)  through our social media pages that we control (collectively, our “Social Media Pages”); (4)  when you visit or stay as a guest at one of our properties, or through other offline interactions such as a call to the call center. Collectively, we refer to the Websites, the Apps and our Social Media Pages, as the “Online Services” and, together with offline channels, the “Services”.  By using the Services, you agree to the terms and conditions of this Privacy Statement. Please read this Privacy Statement carefully.`,
  `Dan Hotels serves as a Data Controller of Personal Data about you.`,
  `You are aware that your Personal Data as defined below, may be stored in Dan Hotels database and that providing the Personal Data is subject to your wish and your consent.
  However, if you do not provide us with the Personal Data that we request, or prohibit us from collecting such data, we may not be able to provide you with the Services.`
]

export const termsAndConditionList = [
  `1.            “Personal Data” means data that identifies you as an individual or relates to an identifiable individual. We collect Personal Data, such as:`,
  `1.1.         Contact information (name, email address, mailing address, phone number, fax number, ID number and passport number);`,
  `1.2.         Payment information (payment card: numbers and expiry date upon PCI DSS rules, billing address, and bank account information);`,
  `1.3.         Demographic data (age, gender, country, and preferred language);`,
  `1.4.         Data about family members and companions, such as names and ages of children;`,
  `1.5.         Information related to your reservation, stay, or visit at Dan Hotels (date of arrival and departure, goods and services purchased, preferences, phone calls executed);`,
  `1.6.         Information necessary to fulfill your special requests and/or specific accommodations (preferences, leisure activities, accompanying guest name, and number of children and ages);`,
  `1.7.         Guest preferences and personalized data (“Personal Preferences”), such as your interests, activities, hobbies, food and beverage choices, services and amenities of which you advise us or which we learn about during your visit;`,
  `1.8.         Loyalty program member information;`,
  `1.9.         Geographical position and other location based information;`,
  `1.10.      Images and video and audio data via security cameras located in public areas, such as hallways and lobbies, in our properties (closed circuit television systems – CCTV and electronic card key).`,
  `2.            “Other Data” means data that does not reveal your specific identity or does not relate to an individual directly. We collect Other Data, such as, browser and device data including ip address and data collected through cookies, pixel tags and other technologies, aggregated data relative to your stays, and responses to promotional offers and surveys.`,
  `3.            In some instances, we may combine Other Data with Personal Data (such as combining your name with your location). If we do, we will treat the combined data as Personal Data as long as it is combined.`,
  `4.            If you submit any Personal Data about other people to us or our service providers (e.g., if you make a reservation for another individual), you represent that you have the authority to do so and you permit us to use the data in accordance with this Privacy Statement.`
]

export const list3 = [
  `We collect Personal Data and Other Data in a variety of ways:`,
  `5.            Online Services. We collect Personal Data when you make a reservation, purchase goods and Services, communicate with us, or otherwise connect with us or post to social media pages, or sign up for a newsletter or participate in a survey, contest or promotional offer or submit a job application.`,
  `6.            Visits and Offline Interactions. We collect Personal Data when you visit Dan Hotels or stay as a guest at Dan Hotel or use Dan Hotel’s Services, when you attend promotional events that we host or in which we participate, or when you provide your Personal Data to facilitate an event. We also collect Personal Data when you visit our properties, information may be collected about you through such properties’ closed circuit television systems (CCTV), electronic key cards and other security systems.`,
  `7.            Other Sources. We may receive Personal Data about you from other third parties. This may include information from your travel agent, loyalty programs, credit card, state authorities in order to meet legal requirements, surveys companies and our sub-contractors and services providers, as well as  other third parties.`,
  `8.            Automatic Information: When you use or interact with our website and/or use any apps that we may make available on our website, we receive and store information generated by your activity and information automatically collected from your browser or mobile device. For example, like many websites, we obtain certain information when your web browser accesses our website including your IP address, browser type, operating system, mobile network data, pages viewed and access times. This information helps us to communicate with our customers and provide them with our Services.`,
  `9.            Customer Support & Call Centers. We collect Personal Data when you make a reservation over the phone, communicate with us by email, fax or via online chat services or contact customer service. These communications may be recorded for purposes of quality assurance and training.`,
  `10.          Aggregated Data. We may aggregate data that we collect and this aggregated data will not personally identify you or any other user.`
]

export const list4 = [
  `11.          We use Personal Data and Other Data for one or more of the purposes detailed below:`,
  `11.1.      Fulfillment of reservation and other purchases: We may process information relating to transactions that you enter into with us and/or through our website ("Transaction Data"). The transaction data may be processed for the purpose of completing your room reservation, supplying the purchased goods and services, customizing our services to your preferences, seeking your feedback on your stay at our properties, and keeping proper records of those transactions.`,
  `11.2.      Guest Relations: To offer hospitality services and goods based on your preferences, information collected during and prior your stays at one of our properties. The information may be used for future voluntary loyalty events operated by us or by our partners.`,
  `11.3.      Response to inquiries: We may process information contained in or relating to any communication that you send to us ("correspondence data"). The correspondence data may include the communication content and metadata associated with the communication (such as groups, meetings and events). The correspondence data may be processed for the purposes of communicating with you and record-keeping.`,
  `11.4.      Internal Business Purposes: For our internal business purposes, developing new products, enhancing the website, improving our services, identifying usage trends and visiting patterns, determining the effectiveness of our promotions, evaluating Third Parties performance (such as Travel Agencies), predicating our yield and occupancy, and meeting contractual obligations.`,
  `11.5.      Administrative and other communications: To send you important information regarding our website, changes to our terms, conditions, and policies, or other administrative information (e.g., information about your travel reservations, such as confirmation emails).`,
  `11.6.      Marketing and promotions: To communicate news and promotions to you regarding Dan Hotels related products and services we think may be of interest to you; including Spa & Gym, Bars and Restaurants.`,
  `11.7.      Safety and security: To maintain your safety and security as well as that of other guests and staff, while you visit at Dan Hotels.`,
  `11.8.      Our legal duties: To comply with legal and regulatory requirements or demands in accordance with applicable law, regulations, or other legal process.`,
  `11.9.      We may use some or all of the Personal Data and Other Data in order to perform certain statistical calculations, some of which may be presented on the App, websites or other services; provided, however, that none of these calculations shall include any personally identifiable information or details.`,
  `12.          The legal basis for processing your Personal Data is made up of one or more of the following reasons:`,
  `12.1.      Your consent.`,
  `12.2.      Providing the Services.`,
  `12.3.      Compliance with applicable laws, regulations or other legal process.`,
  `12.4.      Legitimate Business Interest.`
]

export const list5 = [
  `13.          We share Personal Data and Other Data with the following:`,
  `13.1.      Dan Hotels and properties in order to provide better hospitality experience based upon your preferences, use of our services and prior stays;`,
  `13.2.      A representative or travel advisor that has supplied us with your personal information (for example, your travel agent, personal assistant, employer or spouse who has provided us with your details);`,
  `13.3.      A service Providers and suppliers that assist us in providing services. Examples of such service providers and suppliers are IT service providers, legal advisers, accountants, suppliers of payment services etc.`,
  `13.4.      Our marketing and advertisement partners to provide you with more-relevant ads on our site and to encourage you to return to our site. These partners will not use your data for any other purpose than our legitimate interest of direct marketing and you will always have the right to object. `,
  `13.5.      We may disclose or transfer your Information to a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of Dan Hotels business and assets (including any bankruptcy or similar proceedings).`,
  `14.          We may also disclose Personal Data as we believe to be necessary or appropriate: (a) to comply with  applicable law; (b) to comply with legal process; (c) to respond to requests from public and government authorities, (d) to enforce our terms and conditions; (e) to protect our operations; (f) to protect the rights, privacy, safety or property of Dan Hotels, of you or of others; and (g) to allow us to pursue available remedies or limit the damages that we may sustain. Cross-Border Transfer`,
  `15.          As generally principal, data processing is held within Israel borders, data processing by third parties or cloud service providers may be held on servers around the world such as Microsoft azure, Microsoft 365 , amazon aws etc.`,
  `16.          Notwithstanding, some information might be held on a cloud which might be held outside the borders of Israel or the EU. The operator of such services undertakes to comply with the General Data Protection Regulations provisions. In this specific case, be sure that we will verify that appropriate measures have been put in place to ensure that your Personal Data benefits from an adequate level of protection.`
]

export const list6 = [
  `If you reside in the EU the following provisions will apply to you`,
  `17.          The right to information on the processing of your Personal Data. We strive to provide you with concise, transparent, understandable and easily accessible information on the conditions for processing your personal data, in clear and simple terms.`,
  `18.          The right to access, rectify, delete your Personal Data. The right of access allows you to obtain from us confirmation that your Personal Data have or have not been processed as well as the conditions of such processing, and to receive an electronic copy.`,
  `19.          The right to obtain from us the rectification of your Personal Data.`,
  `20.          Subject to the exceptions provided by applicable law, you have the right to ask us to delete your Personal Data, when one of the following grounds applies:`,
  `20.1.      Your Personal Data are no longer necessary for the purposes for which they were collected or otherwise processed;`,
  `20.2.      You wish to withdraw your consent on which the processing of your Personal Data was based and there is no other basis justifying such processing;`,
  `20.3.      You consider and can establish that your Personal Data has been unlawfully processed;`,
  `20.4.      Your Personal Data must be deleted in accordance with a legal obligation.`,
  `20.5.      The right to restrict the processing of your Personal Data; The applicable regulations provide that this right may be invoked in certain cases, in particular the following:`,
  `20.6.      When you dispute the accuracy of your personal data;`,
  `20.7.      When you consider and can establish that the processing of Personal Data is unlawful but you oppose the deletion of Personal Data and demand instead that the processing be limited;`,
  `20.8.      When we no longer need your Personal Data but they are still necessary for you to establish, exercise or defend your legal rights;`,
  `20.9.      When you object to the processing that would be based on the legitimate interest of the controller, during the verification whether the legitimate grounds pursued by the controller prevail over those of the person in question.`,
  `21.          The right to data portability.`,
  `21.1.      When the processing is based on your consent or a contract, this right to portability allows you to receive the Personal Data you have provided to us with in a structured, commonly used format, and to transmit this Personal Data to another data controller without us hindering it.`,
  `22.          The right to withdraw consent.`,
  `22.1.      When we process your Personal Data on the basis of your consent, this latter may be withdrawn at any time using the means provided for this purpose (procedure indicated below of this Policy). On the other hand, and in accordance with applicable law, the withdrawal of your consent is only valid for the future and cannot therefore call into question the lawfulness of the processing carried out before this withdrawal.`,
  `23.          The right to decide the fate of your Personal Data after your death`,
  `23.1.      To organize the fate of your personal post-mortem data through the adoption of general or specific guidelines.  We are committed to respecting these guidelines. In the absence of directives, we recognize the possibility for heirs to exercise certain rights, in particular the right of access, if it is necessary for the settlement of the deceased’s estate; the right to object to the closure of the deceased’s user accounts; and the right to object to the processing of their data.`,
  `24.          The right to lodge a complaint with a supervisory authority. If, despite our effort to preserve the confidentiality of your personal data, you feel that your rights are not respected, you have the right to lodge a complaint with a supervisory authority. A list of control authorities is available on the European Commission’s website.`,
  `25.          If you reside anywhere outside the EU (including Israel) your rights to review, edit or amend Personal Data held in our Databases will be governed applicable Israeli law, including the provisions of the Protection of Privacy Law, 5741-1981.`
]

export const list7 = [
  `26.          We implement reasonable administrative, organizational and technical safeguards and security measures to protect Personal Data from unauthorized access, disclosure, destruction or alteration, accidental loss, misuse or damage. We regularly review and monitor such safeguards and security measures.`,
  `27.          When disclosure of data to third parties is necessary and authorized, we ensure that these third parties guarantee adequate level of protection and requires contractual guarantees so that the data are exclusively processed for the purposes you have previously accepted, and with the required confidentiality and security.`,
  `28.          If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of your account has been compromised), please immediately notify us in accordance with the “Contact Us” section, below. Retention.`
]

export const list8 = [
  `29.  To the extent permissible by applicable law, we will retain your Personal Data for such period as necessary to satisfy or to fulfill the following:`,
  `29.1.      The purposes for which that Personal Data was provided;`,
  `29.2.      An identifiable and ongoing business need, including record keeping;`,
  `29.3.      A requirement to retain records that may be relevant to any notified regulatory investigations or active legal proceedings;`,
  `29.4.      Comply with any applicable law, regulation, legal process, including, without limitation, court orders and/or compulsory disclosures required by governmental authorities;`,
  `29.5.      Fulfill legitimate interests of Dan Hotels and third parties, such as, defend in cases of legal procedures and etc. Sensitive Data.`
]
export const list9 = [
  `30.          Unless specifically requested, we ask that you not send us, or disclose, on or through the Services or otherwise, to us, any Sensitive Personal Data (e.g., social security numbers, national identification number, data related to racial or ethnic origin, political opinions, religion, ideological or other beliefs, health, biometrics or genetic characteristics, criminal background, administrative or criminal proceedings and sanctions). Use of Services by Minors.`
]
export const list10 = [
  `31.          Dan Hotels does not knowingly collect Personal Data from any person under the age of 18.`,
  `32.          Dan Hotels may collect Personal Data from people under the age of 18 as part of the guest registration process, but always with the consent of such person’s parent or guardian. Updates.`
]

export const list11 = [
  `33.          We are entitled to change and update this Privacy Policy from time to time, at our sole discretion, without having to provide notice thereof. Responsibility for becoming familiar with the changes, if any, made by this Privacy Policy rests with you alone. Cookies`
]
export const list12 = [
  `34.          Like many other websites, in order to enhance your experience on our web site, some of our web pages may use “cookies". Cookies, by themselves, do not tell us your personally identifiable information unless you choose to provide this information to us (by, for example, registering for one of our services). However, once you choose to furnish the site with your personally identifiable information, this information may be linked to the data stored in the cookie.`,
  `35.          A cookie is a small text file containing small amounts of information which is placed by a website onto a computer or device. Cookies are designed to assist a computer or device to remember something the user has done within that website, for example remembering that the user has logged in, or which buttons have been clicked.`,
  `36.          We use cookies to understand site usage and to improve the content and offerings on our sites. We may use cookies to personalize your experience at our web pages and to offer you relevant products, programs, or services as well as interest-based or targeted advertising.`,
  `37.          Disabling Cookies. Your browser may give you the ability to control cookies; certain browsers can be set to avoid cookies collection. If you do not agree to the use of cookies please disable them by following the instructions for your browser set out here, Please note that certain features on our website may not work properly. Some cookie organizations provide an automated disabling tool in respect of its cookie(s), see the list of these 3rd party cookies with a link to its automated disabling tool. In other cases you can disable & control them if you want through 3rd party control tools like this.`,
  `38.          We may use various types of Cookies:`,
  `38.1.      Session Cookies . These Cookies are stored only temporarily during a browsing session and are deleted from your device when you close the browser. We use Session Cookies to support the functionality of the site and to understand your use of the site, for example which pages you visit, which links you use and how long you stay on each page.`,
  `38.2.      Essential Cookies. These cookies are necessary for the operation of the site and, for example, enable an e-dan member to log-in & enjoy member benefits.`,
  `38.3.      Functional Cookies. We use Functional Cookies to save your settings on the site - settings such as your currency preference when looking at hotel prices on the site. We also use Functional Cookies to store data so that you can easily find it the next time you visit. Some Functional Cookies are essential to viewing maps or videos on our site. We also may use "Flash Cookies" for some of our animated content.`,
  `38.4.      Persistent Cookies. These Cookies are not deleted when you close your browser but are saved on your device for a fixed period or until you delete them. Each time you visit the site, the server that set the Cookie will recognize the persistent Cookie saved on your device. We and others use persistent Cookies to store your preferences, so that they are available for your next visit, for example if you already logged into e-dan, these cookies will skip the log-in when you visit our site again.`,
  `38.5.      Targeting and advertising Cookies. These cookies are used to collect information from you to help us to improve our products and services as well as serve you with targeted advertisements that we believe will be relevant to you. We use targeting cookies on our websites for various marketing initiatives and campaigns. We also may use some Analytics Cookies and Other Technologies to facilitate advertising.`,
  `38.6.      Analytics Cookies. These cookies collect information about your use of the site, and enable us to improve the way it works. These Cookies give us aggregated information that we use to monitor site performance, count page visits, spot technical errors, see how users reach the Site, and measure the effectiveness of advertising & Targeted ads`,
  `39.          The Website uses the following third party cookies:`,
  `39.1.      Google cookies. Google analytics cookie are used to collect information about how visitors use our site. We use the information to compile reports and to help improve our site. These cookies collect information in an anonymous form, for example determine the number of visitors, where the visitors have come to the site from and what buttons the visitor clicked. To read Google’s privacy policy in respect of Google Analytics click here to opt out of Google analytics click here. Google cookies also include Google AdWords & DoubleClick cookies for marketing , advertising & remarketing these enable to recognize visitors on the websites of our advertising partners and to address them with interest-related information or ads. To find out about Googles double-click cookies click here.`,
  `39.2.      Facebook cookies. These cookies are used for interest-based advertising meaning ads you might like to see based on your activity on websites off of Facebook. These cookies collects information from your site visit and enables promoting you ads through Facebook platforms. To read more about interest-based ads from Facebook click here. To unsubscribe from Facebook's interest-based ads, please follow Facebook's instructions here. More information about Facebook's privacy policy can be found here in the Facebook Privacy Policy.`,
  `39.3.      Bing cookies. Bing cookies are used to collect information about how visitors use our site & for marketing, advertising, remarketing purposes these cookies enable to recognize visitors on the websites of our advertising partners and to address them with interest-related information or ads. To read more about Microsoft/Bing privacy click here.`
]
export const list13 = [
  `40.          In matters pertaining to European laws for the protection of privacy, our DPO is Mr Avinoam Ravad.`
]
export const list14 = [
  `If you have any questions about this Privacy Policy or our privacy practices, please contact us at`
]
export const Divider = `Collection of Personal Data and of Other Data`
export const termsAndConditionListTitle2 = `How We Collect Personal Data and Other Data`
export const Divider3 = ` Use of Personal Data and Other Data`
export const Divider4 = `Disclosure of Personal Data and Other Data`
export const Divider5 = `How You Can Access, Change, or Limit the Use of Your Personal Data`
export const Divider6 = 'Security'
export const Divider7 = 'Retention'
export const Divider8 = `Sensitive Data`
export const Divider9 = `Use of Services by Minors`
export const Divider10 = `Updates`
export const Divider11 = `Cookies`
export const Divider12 = `Data Protection Officer (“DPO”)`
export const Divider13 = `Contact Us`

export const DPOMail = 'DPO@danhotels.com'
