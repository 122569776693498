import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'

function CheckBox({ handleChange, status, withOutTop }) {
  return (
    <div>
      <Checkbox
        checked={status}
        onChange={() => handleChange()}
        value="checked"
        style={{ color: '#f3d250', fontSize: '20px', paddingTop: withOutTop && 0 }}
      />
    </div>
  )
}
export default CheckBox
