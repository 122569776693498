import React from 'react'
import Grid from '@material-ui/core/Grid'
import HotelInput from '../../../Components/HotelInput'
import HotelButton from '../../../Components/HotelButton'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HotelLoader from '../../../Components/HotelLoader'
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  title: {
    fontSize: '20px',
    color: '#fff'
  },
  error: {
    fontWeight: 700,
    color: 'rgb(255, 60, 60)',
    marginTop: '5px',
    textAlign: 'center',
    fontSize: '20px',
    textShadow: 'black -1px 0px, black 0px 1px, black 1px 0px, black 0px -1px'
  }
})
const FormComponent = props => {
  return (
    <Grid item xs={12} ms={12} md={12} lg={12}>
      <Typography className={props.classes.title}>{props.title}</Typography>
      <HotelInput
        sendValue={props.sendValue}
        id="roomNumber"
        type="text"
        name="roomNumber"
        placeholder="Room Number"
      />
      <HotelInput
        sendValue={props.sendValue}
        id="lastName"
        type="text"
        name="lastName"
        placeholder="Last Name"
      />
      <div className={props.classes.error}>{props.error}</div>
      {props.loader ? <HotelLoader color=" #ef7066" /> : ''}
      <HotelButton
        text="login"
        handleClick={props.formSubmit}
        buttonStyles={{ marginBottom: '25px', marginTop: '15px' }}
      />
    </Grid>
  )
}

export default withStyles(styles)(FormComponent)
