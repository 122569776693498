import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import HotelButton from '../../Components/HotelButton'
import Typography from '@material-ui/core/Typography'
import { danSmartConnect } from '../../Keys'
import HotelLoader from '../../Components/HotelLoader'
// import axios from 'axios'
import FormLabel from '@material-ui/core/FormLabel'
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  title: {
    fontSize: '22px',
    color: '#fff'
  },
  error: {
    fontWeight: 700,
    color: 'rgb(255, 60, 60)',
    marginTop: '5px',
    textAlign: 'center',
    fontSize: '20px',
    textShadow: 'black -1px 0px, black 0px 1px, black 1px 0px, black 0px -1px'
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
})

const params = new URL(window.location.href).searchParams
class FreeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      loader: false,
      value: ''
    }
  }
  componentWillUnmount() {
    this.setState({ loader: false })
  }
  componentWillUpdate(nextProps) {
    if (nextProps.checkerStatus !== this.props.checkerStatus) {
      this.setState({ error: '' })
    }
  }
  handleSubmit = e => {
    e.preventDefault()
    if (this.props.checkerStatus) {
      this.setState({ error: '', loader: true })
      let submitObj = {
        password: e.target.password.value
      }
      if (e.target.user) {
        submitObj.user = e.target.user.value;
      }
      if (e.target.username) {
        submitObj.username = 'ddddddddd';
      }
      e.target.submit(submitObj)
    } else {
      this.setState({ loader: false, error: 'Please accept terms and conditions' })
    }
  }

  _handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.handleSubmit()
    }
  }

  render() {
    let { classes, style } = this.props
    let { error, loader } = this.state
    let params = new URL(window.location.href).searchParams
    return (
      <div className="FreeForm" style={{ width: '100%', height: '100%' }}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.root}
          spacing={0}
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          {window.location.href.indexOf('/bwifi') > -1 ? (
              <form
                className={classes.form}
                onSubmit={this.handleSubmit}
                name="form1"
                method="post"
                action="https://captiveportal-login.2plus.co.il/cgi-bin/login"
              >
                <FormLabel
                  style={{
                    color: 'white',
                    fontSize: '25px',
                    marginBottom: '15px'
                  }}
                  component="legend"
                >
                  Free login
                </FormLabel>
                <input type="hidden" name="cmd" value="authenticate" />
                <input type="hidden" name="user" value="freeuser" />
                <input type="hidden" name="password" value="freespeed" />
                <div className={classes.error}>{error}</div>
                {loader ? <HotelLoader color=" #ef7066" /> : ''}
                <input
                  type="submit"
                  value="Connect"
                  style={{
                    width: '70%',
                    background: 'transparent',
                    height: '50px',
                    color: 'white',
                    borderRadius: '23px',
                    cursor: 'pointer',
                    border:
                      window.location.href.indexOf('link') > -1
                        ? '3px solid #f8b034'
                        : '3px solid #ef7066',
                    fontSize: '18px',
                    marginTop: '15px'
                  }}
                />
              </form>
            ) : (
            <form className={classes.form}
              action={danSmartConnect}
              method="post"
              onSubmit={this.handleSubmit}
            >
              <Typography variant="h4" className={classes.title}>
                {this.props.title}
              </Typography>
              <div className={classes.container}>
                <div className={classes.error}>{error}</div>
                {loader ? <HotelLoader color=" #ef7066" /> : ''}
                <input type="hidden" name="username" value="danwifiguest" />
                <input type="hidden" name="password" value="danhotels" />
                <input type="hidden" name="url" value={params.get('url')} />
                <input type="hidden" name="proxy" value={params.get('proxy')} />
                <input type="hidden" name="uip" value={params.get('uip')} />
                <input type="hidden" name="client_mac" value={params.get('client_mac')} />
                <input
                  type="submit"
                  value={window.location.href.indexOf('link') > -1
                    ? 'Connect'
                    : 'Free login'
                  }
                  style={{
                    width: '70%',
                    background: 'transparent',
                    height: '50px',
                    color: 'white',
                    borderRadius: '23px',
                    cursor: 'pointer',
                    border:
                      window.location.href.indexOf('link') > -1
                        ? '3px solid #f8b034'
                        : '3px solid #ef7066',
                    fontSize: '18px',
                    marginTop: '15px'
                  }}
                />
              </div>
            </form>
          )}
        </Grid>
      </div>
    )
  }
}
FreeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  screen: PropTypes.object.isRequired
}
export default withStyles(styles)(FreeForm)
