import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import TermsCard from '../../Components/TermsCard'
import {
  termsAndConditionList,
  termsAndConditionTopList,
  Divider,
  termsAndConditionListTitle2,
  list3,
  Divider3,
  list4,
  list5,
  Divider4,
  Divider5,
  Divider6,
  Divider7,
  Divider8,
  Divider9,
  Divider10,
  Divider11,
  Divider12,
  Divider13,
  list6,
  list7,
  list8,
  list9,
  list10,
  list11,
  list12,
  list13,
  list14,
  DPOMail
} from './components/content'
const styles = theme => ({
  root: {
    flexGrow: 1
  }
})

const ConditionContainer = props => {
  let { classes } = props
  return (
    <div className="TermsContainer" style={{ background: '#f8f8f8' }}>
      <Grid
        container
        className={classes.root}
        spacing={0}
        justify="center"
        align="center"
      >
        <Grid item xs={12} ms={12} md={6} lg={6}>
          <TermsCard
            Divider={Divider}
            Divider2={termsAndConditionListTitle2}
            Divider3={Divider3}
            Divider4={Divider4}
            Divider5={Divider5}
            Divider6={Divider6}
            Divider7={Divider7}
            Divider8={Divider8}
            Divider9={Divider9}
            Divider10={Divider10}
            Divider11={Divider11}
            Divider12={Divider12}
            Divider13={Divider13}
            title="Privacy Policy"
            list={termsAndConditionTopList}
            list2={termsAndConditionList}
            list3={list3}
            list4={list4}
            list5={list5}
            list6={list6}
            list7={list7}
            list8={list8}
            list9={list9}
            list10={list10}
            list11={list11}
            list12={list12}
            list13={list13}
            list14={list14}
            DPOMail={DPOMail}
          />
        </Grid>
      </Grid>
    </div>
  )
}

ConditionContainer.propTypes = {
  classes: PropTypes.object.isRequired
}
export default withStyles(styles)(ConditionContainer)
