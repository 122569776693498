import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import LinkImage from '../../Components/LinkImage'
import logo from '../../images/logo.png'
import Header from './components/Header'
const styles = theme => ({
  link: {
    margin: theme.spacing.unit
  },
  tools: {
    padding: '4px'
  }
})
const url2 = '/'

class HeaderBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }
  componentWillMount() {
    this.updateDimensions()
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
  }
  updateDimensions = () => {
    this.setState(prevState => ({
      ...prevState,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    }))
  }
  render() {
    let { windowHeight, windowWidth } = this.state
    const { classes } = this.props
    let screen = { screenWidth: windowWidth, screenHeight: windowHeight }
    return (
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'rgb(255, 255, 255)'
          }}
        >
          <Toolbar className={classes.tools}>
            <LinkImage src={logo} title="dan hotel logo" url={url2} />
            <Header
              screen={screen}
              title="dan hotels"
              subTitle="Experience the Best"
            />
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

HeaderBar.propTypes = {
  classes: PropTypes.object.isRequired
  // screen: PropTypes.object.isRequired
}

export default withStyles(styles)(HeaderBar)
