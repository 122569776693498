import React from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import FreeForm from '../../FreeForm'

const Free = ({ screen, checkerStatus, style }) => {
  return (
    <Grid
      item
      xs={
        screen.screenWidth < 624 ||
        window.location.href.indexOf('link') > -1
          ? 12
          : 6
      }
      ms={
        window.location.href.indexOf('link') > -1
          ? 12
          : 6
      }
      md={
        window.location.href.indexOf('link') > -1
          ? 6
          : 4
      }
      lg={
        window.location.href.indexOf('link') > -1
          ? 6
          : 4
      }
    >
      <FreeForm
        style={style}
        screen={screen}
        title={
          window.location.href.indexOf('link') > -1
            ? 'Free Wi-Fi'
            : 'Free Internet'
        }
        checkerStatus={checkerStatus}
      />
    </Grid>
  )
}

Free.propTypes = {
  // classes: PropTypes.object.isRequired,
  screen: PropTypes.object.isRequired
}
export default Free
