import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Header from './Containers/Header'
import MainContainer from './Containers/Main'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import BWifiContainer from './Containers/BWifiContainer'
import LinkContainer from './Containers/LinkContainer'
import TermsContainer from './Containers/TermsContainer'
import ConditionsContainer from './Containers/ConditionsContainer'
import VillaContainer from './Containers/VillaContainer'
import NoMatch from './Containers/NoMatch'
import HeaderYamBar from './Containers/Header/HeaderYamBar'
const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      text: {
        // Some CSS
        background: 'linear-gradient(left,#6441a5,#6c52ad)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 3px 2px rgb(59,141,153,.3)'
      }
    }
  },
  typography: { useNextVariants: true }
})

class App extends Component {
  getHeader = () => {
    switch (window.location.href.split('/').pop()) {
      case "link":
        return null
      case "villaaccadia": 
        return null
      case "yambar":
        return <HeaderYamBar />
      default:
        return <Header />
    }
  }
  render() {
    return (
      <div className="App" style={{
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column' }}>
        <MuiThemeProvider theme={theme}>
          {this.getHeader()}
          <Router>
            <Switch>
              <Route
                exact
                path="/"
                component={props => <MainContainer {...props} />}
              />
              <Route
                exact
                path="/bwifi"
                component={props => <BWifiContainer {...props} />}
              />
              <Route
                exact
                path="/link"
                component={props => <LinkContainer {...props} />}
              />
              <Route
                exact
                path="/wifiterms"
                component={() => <TermsContainer />}
              />
              <Route
                exact
                path="/policy"
                component={() => <ConditionsContainer />}
              />
              <Route
                exact
                path="/villaaccadia"
                component={() => <VillaContainer />}
              />
              <Route
                exact
                path="/yambar"
                component={props => <MainContainer {...props} />}
              />
              <Route component={NoMatch} />
            </Switch>
          </Router>
        </MuiThemeProvider>
      </div>
    )
  }
}

export default App
