import React from 'react'
import Fab from '@material-ui/core/Fab'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
    width: '70%',
    border:
      window.location.href.indexOf('link') > -1
        ? '3px solid #f8b034'
        : '3px solid #ef7066'
  },
  icon: {
    color: window.location.href.indexOf('link') > -1 ? '#1d3641' : 'white'
  }
})
const HotelButton = ({ classes, handleClick, text, buttonStyles }) => {
  return (
    <Fab
      variant="extended"
      aria-label="Add"
      className={classes.button}
      style={{
        ...buttonStyles,
        backgroundColor:
          window.location.href.indexOf('link') > -1 ? '#f8b034' : 'transparent'
      }}
      onClick={() => {
        handleClick()
      }}
    >
      <div className={classes.icon}>
        <Typography className={classes.icon}>{text}</Typography>
      </div>
    </Fab>
  )
}

HotelButton.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  buttonStyles: PropTypes.object.isRequired
}

export default withStyles(styles)(HotelButton)
