import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import { Link as Linked } from 'react-router-dom'
const styles = theme => ({
  link: {
    margin: theme.spacing.unit
  },
  image: {
    marginLeft: '5px'
  },
  imageContainer: {
    margin: 8
  }
})
const jsLink = '/'

function LinkImage({ classes, src, title, url }) {
  if (src === '') {
    return (
      <Linked to={url} target="_blank">
        <Link href={jsLink} className={classes.link}>
          {title}
        </Link>
      </Linked>
    )
  } else {
    return (
      <div className={classes.link}>
        <img src={src} alt={title} height="76px" />
      </div>
    )
  }
}

LinkImage.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
}

export default withStyles(styles)(LinkImage)
