import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormComponent from './components/FormComponent'
import { smartConnect, lettersRegExpHeb } from '../../Keys'
const styles = theme => ({
  root: {
    flexGrow: 1
  },
  form: {
    flexGrow: 1,
    textAlign: 'center'
  }
})

class GuestForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      roomNumber: '',
      lastName: '',
      checked: false,
      error: '',
      loader: false
    }
  }
  componentWillUpdate(nextProps) {
    if (nextProps.checkerStatus !== this.props.checkerStatus) {
      this.setState({ error: '' })
    }
  }
  ValueValidate = (title, field) => {
    switch (true) {
      case field === 'roomNumber':
        this.setState(prevState => ({
          ...prevState,
          roomNumber: title,
          error: ''
        }))
        break
      case field === 'lastName' && !lettersRegExpHeb.test(title):
        this.setState(prevState => ({
          ...prevState,
          lastName: title,
          error: ''
        }))
        break
      case field === 'lastName' && lettersRegExpHeb.test(title):
        this.setState(prevState => ({
          ...prevState,
          lastName: title,
          error: 'Last name must contain English letters only'
        }))
        break
      default:
        break
    }
  }
  formSubmit = () => {
    let { roomNumber, lastName } = this.state
    let checked = this.props.checkerStatus
    switch (true) {
      case !checked:
        this.setState(prevState => {
          return {
            ...prevState,
            error: 'Please accept terms and conditions'
          }
        })
        break
      case roomNumber === '':
        this.setState(prevState => {
          return {
            ...prevState,
            error: 'Please complete all fields to continue'
          }
        })
        break
      case lastName === '':
        this.setState(prevState => {
          return {
            ...prevState,
            error: 'Please complete all fields to continue'
          }
        })
        break
      case lettersRegExpHeb.test(lastName):
        this.setState(prevState => ({
          ...prevState,
          error: 'Last name must contain English letters only'
        }))
        break
      case checked && roomNumber !== '' && lastName !== '':
        this.setState(prevState => {
          return {
            ...prevState,
            error: '',
            loader: true
          }
        })
        setTimeout(
          () =>
            (window.location.href = `${smartConnect}?username=${roomNumber}&password=${lastName}`),
          3000
        )
        break
      default:
        this.setState(prevState => ({
          ...prevState,
          error: 'something went wrong, please try again',
          loader: false
        }))
        break
    }
  }
  _handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.formSubmit()
    }
  }

  render() {
    let { classes, title } = this.props
    let { error, loader } = this.state
    return (
      <div className="GuestForm">
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.root}
          spacing={0}
        >
          <form className={classes.form} onKeyPress={this._handleKeyPress}>
            <FormComponent
              sendValue={this.ValueValidate}
              formSubmit={this.formSubmit}
              title={title}
              error={error}
              loader={loader}
            />
          </form>
        </Grid>
      </div>
    )
  }
}
GuestForm.propTypes = {
  classes: PropTypes.object.isRequired,
  screen: PropTypes.object.isRequired
}
export default withStyles(styles)(GuestForm)
